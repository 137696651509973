import React, { useState } from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'
import { PostModal } from 'components/collections/Post'
import { withSizes } from 'components/helpers'

import Feed from './Feed'
import Welcome from './Welcome'
import { GroupSection } from './Feed.styles'

import * as S from './Home.styles'

import Banner from './Banner'

const defaultProps = {}
const propTypes = {}

export const Home = props => {
  const router = useRouter()
  const { user, postData, isMobile } = props
  const { post, tab = user ? 'feed' : 'Latest', page = 1 } = router.query
  const [lastRoute, setLastRoute] = useState(`?tab=${tab}&page=${page}`)
  const [customItems, setCustomItems] = useState([])

  const CompTest = user ? () => <div /> : Welcome

  const onPostClose = () => {
    setCustomItems([])
    router.push(lastRoute || '/', undefined, {
      shallow: true,
    })
  }

  const onPostOpen = () => {
    setLastRoute(`?tab=${tab}&page=${page}`)
  }

  const onPost = ({ postData }) => {
    setCustomItems(i => [{ ...postData, type: 'Post' }, ...i])
  }

  return (
    <S.Container>
      {!post && (
        <Head>
          <meta
            name="robots"
            content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
          ></meta>
          <title>
            {
              'TrialSiteNews | Independent Censorship-Free Media for Health & Biomedical Research'
            }
          </title>
          <meta
            name="description"
            content="Transparent coverage of clinical research"
          />
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          />
          <meta name="twitter:card" content="summary_large_image"></meta>
          <meta
            name="og:title"
            content={
              'TrialSiteNews | Independent Censorship-Free Media for Health & Biomedical Research'
            }
          ></meta>
          {typeof window !== 'undefined' && window?.location && (
            <meta property="og:url" content={window.location.href} />
          )}
          <meta
            property="og:description"
            content={'Transparent coverage of clinical research'}
          />
        </Head>
      )}
      {/* <W.AskForAFavor>
        <W.AskForAFavorHeader>
          We ask you, humbly: don&apos;t scroll away.
        </W.AskForAFavorHeader>

        <W.AskForAFavorDesc>
          In our commitment to delivering unbiased and impactful news,
          we&apos;ve always steered clear of corporate influences and massive
          profit pursuits. However, this approach means we don&apos;t have a
          large sales team or substantial advertising revenue. Today, we find
          ourselves in need of your support to stay afloat. Your donations are
          vital to cover our operational costs and ensure our continued service
          as your reliable news source. Every contribution, big or small, makes
          a significant difference. Please consider supporting us in our mission
          to bring you the news that matters.
          <br />
          <br />
          Support Independent Journalism
          <stripe-buy-button
            buy-button-id="buy_btn_1MyFq2FtdyGfVJnrDYxDR5aJ"
            publishable-key="pk_live_qMQLyveayPg8niFCQ7nUt5nY00K4V5u7Gx"
          >
            Donate Now
          </stripe-buy-button>
        </W.AskForAFavorDesc>
      </W.AskForAFavor> */}
      {post && (
        <PostModal
          postId={post}
          data={postData}
          onClose={onPostClose}
          onDelete={postId =>
            setCustomItems(i =>
              i.filter(({ id }) => Number(id) !== Number(postId))
            )
          }
        />
      )}
      <CompTest />
      {!post && (
        <Feed
          defaultTab="Trending"
          CustomHeader={
            user ? (
              <>
                {isMobile && <Banner style={{ marginTop: 20 }} />}
                {isMobile && user && <GroupSection />}
                <S.StyledPost onPost={onPost} />
              </>
            ) : null
          }
          onPostOpen={onPostOpen}
          customItems={customItems}
          setCustomItems={setCustomItems}
        />
      )}
    </S.Container>
  )
}

Home.defaultProps = defaultProps
Home.propTypes = propTypes

export default withSizes(Home)
