import styled from 'styled-components'
import { PostInput } from 'components/collections/Post'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledPost = styled(PostInput)`
  padding-bottom: 30px;
  ${({ theme }) => theme.media.phone} {
    padding-top: 30px;
  }
`
