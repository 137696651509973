import React from 'react'
import Page from 'routes/Home'
import api, { getUserFromCtx } from 'shared/api'

export async function getServerSideProps(context) {
  const user = getUserFromCtx(context)
  const { query } = context
  const { post: postId } = query

  if (postId) {
    try {
      const postResquests = [
        api.get(`/public/posts/${postId}`),
        api.get('/comments', {
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
          },
          params: {
            reference: postId,
            type: 'Post',
            limit: 10,
            page: 1,
          },
        }),
      ]

      const [
        { data: postData },
        {
          data: commentsList,
          headers: { 'x-total-results': commentCount } = {},
        },
      ] = await Promise.all(postResquests)

      const { data: isLiked } = user?.accessToken
        ? await api.get(`/posts/liked/${postId}`, {
            headers: { Authorization: `Bearer ${user?.accessToken}` },
          })
        : { data: false }

      return {
        props: {
          user,
          postData: {
            ...postData,
            isLiked: isLiked || null,
            commentsData: {
              list: commentsList || [],
              total: commentCount || 0,
            },
          },
          postId,
          seoConfig: {
            image: postData?.image || null,
            title: postData?.text || null,
            content: postData?.text || null,
          },
        },
      }
    } catch (e) {
      return {
        props: {
          errorCode: 404,
        },
      }
    }
  }

  return {
    props: { user },
  }
}

const Home = props => <Page {...props} />

export default Home
